// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-3-dsecure-tsx": () => import("./../../../src/pages/3dsecure.tsx" /* webpackChunkName: "component---src-pages-3-dsecure-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parents-checkout-complete-tsx": () => import("./../../../src/pages/parents/checkout/complete.tsx" /* webpackChunkName: "component---src-pages-parents-checkout-complete-tsx" */),
  "component---src-pages-parents-checkout-retry-tsx": () => import("./../../../src/pages/parents/checkout/retry.tsx" /* webpackChunkName: "component---src-pages-parents-checkout-retry-tsx" */),
  "component---src-pages-shop-checkout-tsx": () => import("./../../../src/pages/shop/checkout.tsx" /* webpackChunkName: "component---src-pages-shop-checkout-tsx" */),
  "component---src-pages-shop-index-tsx": () => import("./../../../src/pages/shop/index.tsx" /* webpackChunkName: "component---src-pages-shop-index-tsx" */),
  "component---src-pages-shop-order-complete-tsx": () => import("./../../../src/pages/shop/order/complete.tsx" /* webpackChunkName: "component---src-pages-shop-order-complete-tsx" */),
  "component---src-pages-shop-order-failed-tsx": () => import("./../../../src/pages/shop/order/failed.tsx" /* webpackChunkName: "component---src-pages-shop-order-failed-tsx" */),
  "component---src-pages-shop-products-escape-from-conform-prison-personalise-index-tsx": () => import("./../../../src/pages/shop/products/escape-from-conform-prison/personalise/index.tsx" /* webpackChunkName: "component---src-pages-shop-products-escape-from-conform-prison-personalise-index-tsx" */),
  "component---src-pages-shop-products-subscription-gift-voucher-personalize-index-tsx": () => import("./../../../src/pages/shop/products/subscription-gift-voucher/personalize/index.tsx" /* webpackChunkName: "component---src-pages-shop-products-subscription-gift-voucher-personalize-index-tsx" */),
  "component---src-pages-teachers-start-tsx": () => import("./../../../src/pages/teachers/start.tsx" /* webpackChunkName: "component---src-pages-teachers-start-tsx" */),
  "component---src-templates-blog-post-category-template-tsx": () => import("./../../../src/templates/BlogPostCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-category-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-checkout-page-template-tsx": () => import("./../../../src/templates/CheckoutPageTemplate.tsx" /* webpackChunkName: "component---src-templates-checkout-page-template-tsx" */),
  "component---src-templates-curriculum-category-template-tsx": () => import("./../../../src/templates/CurriculumCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-curriculum-category-template-tsx" */),
  "component---src-templates-curriculum-page-template-tsx": () => import("./../../../src/templates/CurriculumPageTemplate.tsx" /* webpackChunkName: "component---src-templates-curriculum-page-template-tsx" */),
  "component---src-templates-dynamic-page-template-tsx": () => import("./../../../src/templates/DynamicPageTemplate.tsx" /* webpackChunkName: "component---src-templates-dynamic-page-template-tsx" */),
  "component---src-templates-resource-category-template-tsx": () => import("./../../../src/templates/ResourceCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-resource-category-template-tsx" */),
  "component---src-templates-resource-page-template-tsx": () => import("./../../../src/templates/ResourcePageTemplate.tsx" /* webpackChunkName: "component---src-templates-resource-page-template-tsx" */),
  "component---src-templates-shop-category-template-tsx": () => import("./../../../src/templates/ShopCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-shop-category-template-tsx" */),
  "component---src-templates-shop-product-template-custom-escape-from-conform-prison-tsx": () => import("./../../../src/templates/ShopProductTemplate/custom/EscapeFromConformPrison.tsx" /* webpackChunkName: "component---src-templates-shop-product-template-custom-escape-from-conform-prison-tsx" */),
  "component---src-templates-shop-product-template-index-tsx": () => import("./../../../src/templates/ShopProductTemplate/index.tsx" /* webpackChunkName: "component---src-templates-shop-product-template-index-tsx" */),
  "component---src-templates-special-offer-template-tsx": () => import("./../../../src/templates/SpecialOfferTemplate.tsx" /* webpackChunkName: "component---src-templates-special-offer-template-tsx" */),
  "component---src-templates-static-page-template-tsx": () => import("./../../../src/templates/StaticPageTemplate.tsx" /* webpackChunkName: "component---src-templates-static-page-template-tsx" */)
}

